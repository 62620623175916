import { FormEvent, useRef } from "react";
import { useStore } from "@nanostores/react";
import ContactField from "@components/contact/contactField";
import contactStore, { FieldStates, ContactFormKeys } from "@stores/contactStore";
import contactFields from "@data/contact";
import { delay } from "@utils/delay";
import css from "@styles/components/contact/contact.module.scss";

export default function Contact() {
	const contactFormStore = useStore(contactStore);
	const fieldsRef = useRef<Record<ContactFormKeys, HTMLDivElement>>(Object());

	const validateForm = () => {
		let isValid = true;
		for (const contactFieldData of contactFields) {
			if (contactFieldData.isValid(contactFormStore[contactFieldData.name].value.get())) {
				contactFormStore[contactFieldData.name].fieldState.set(FieldStates.VALID);
			} else {
				contactFormStore[contactFieldData.name].fieldState.set(FieldStates.INVALID);
				if (isValid) {
					fieldsRef.current[contactFieldData.name].scrollIntoView({ block: "center" });
					isValid = false;
				}
			}
		}
		return isValid;
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		const form = e.currentTarget;
		e.preventDefault();

		Object.values(contactFormStore).forEach((contactFieldStore) => {
			contactFieldStore.fieldState.set(FieldStates.NEUTRAL);
		});
		await delay(10);
		if (validateForm()) {
			form.submit();
		}
	};

	return (
		<form
			className={css["contact-container"]}
			action="https://getform.io/f/88a483db-430e-4f89-aef6-0c83aa8c68b1"
			method="POST"
			target="_blank"
			rel="noreferrer"
			spellCheck="false"
			noValidate
			onSubmit={(e) => void handleSubmit(e)}
		>
			{contactFields.map((contactFieldData) => (
				<div
					key={contactFieldData.name}
					className={css["field-container"]}
					ref={(field) => Object.assign(fieldsRef.current, { [contactFieldData.name]: field })}
				>
					<ContactField
						contactFieldData={contactFieldData}
						valueAtom={contactFormStore[contactFieldData.name].value}
						fieldStateAtom={contactFormStore[contactFieldData.name].fieldState}
					/>
				</div>
			))}
			<button type="submit" className={css.submit}>
				Submit
			</button>
		</form>
	);
}

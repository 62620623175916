import css from "@styles/components/about/info.module.scss";

export default function Info() {
	return (
		<div className={css["info-container"]}>
			<p>
				I'm a Full Stack Developer at Israel Defense Forces.
				<br />
				Before my military service, I was a Software Developer Intern at Check Point Software Technologies Ltd.
				<br />
				Graduate of the Magshimim program.
			</p>
			<p>My specialties are React, Next.js, Node.js, Python, and C++. Motivated to learn new things every day.</p>
			<p>
				Check out my work in the{" "}
				<a href="./#projects">
					<strong className={css["link-text"]}>Projects</strong>
				</a>{" "}
				section below or my{" "}
				<a href="https://github.com/RonKonis" target="_blank" rel="noreferrer">
					<strong className={css["link-text"]}>GitHub</strong>
				</a>{" "}
				account.
				<br />
				Feel free to{" "}
				<a href="./#contact">
					<strong className={css["link-text"]}>Contact</strong>
				</a>{" "}
				me here or connect on{" "}
				<a href="https://www.linkedin.com/in/ronkonis" target="_blank" rel="noreferrer">
					<strong className={css["link-text"]}>LinkedIn</strong>
				</a>
				.
			</p>
		</div>
	);
}

import SectionListItem from "@layout/header/sectionListItem";
import sections from "@data/sections";
import css from "@styles/layout/header/navigationBar.module.scss";

export default function NavigationBar() {
	return (
		<nav className={css["navigation-bar"]}>
			<ul className={css["sections-list"]}>
				{sections.map((section) => (
					<SectionListItem key={section.id} section={section.id} />
				))}
			</ul>
		</nav>
	);
}

import Profile from "@components/about/profile";
import Info from "@components/about/info";
import Skills from "@components/about/skills";
import css from "@styles/components/about/about.module.scss";

export default function About() {
	return (
		<div className={css["about-container"]}>
			<Profile />
			<Info />
			<Skills />
		</div>
	);
}

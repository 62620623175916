const skills: string[] = [
	"React",
	"Next.js",
	"Node.js",
	"Python",
	"C++",
	"C",
	"TypeScript",
	"JavaScript",
	"GraphQL",
	"HTML",
	"SASS",
	"CSS",
	"Regex",
	"Git",
	"SQLite",
];

export default skills;

import Icon from "@layout/header/icon";
import NavigationBar from "@layout/header/navigationBar";
import css from "@styles/layout/header/header.module.scss";

export default function Header() {
	return (
		<header className={css["header-container"]}>
			<div className={css["header-content"]}>
				<Icon />
				<NavigationBar />
			</div>
		</header>
	);
}

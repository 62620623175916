import { ChangeEvent, FocusEvent, useMemo } from "react";
import { WritableAtom } from "nanostores";
import { useStore } from "@nanostores/react";
import { ContactFieldData } from "@data/contact";
import { FieldStates } from "@stores/contactStore";
import { removeWhiteSpaces } from "@utils/regex";
import { capitalize } from "@utils/capitalize";
import css from "@styles/components/contact/contactField.module.scss";

type Props = {
	contactFieldData: ContactFieldData;
	valueAtom: WritableAtom<string>;
	fieldStateAtom: WritableAtom<FieldStates>;
};

export default function ContactField({ contactFieldData, valueAtom, fieldStateAtom }: Props) {
	const fieldValue = useStore(valueAtom);
	const fieldState = useStore(fieldStateAtom);

	const fieldAttributes = useMemo(() => {
		return {
			className: css["field-input"],
			name: contactFieldData.name,
			value: fieldValue,
			placeholder: `Enter your ${contactFieldData.name}`,
			required: true,
			onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
				valueAtom.set(e.target.value);
			},
			onFocus: () => {
				fieldStateAtom.set(FieldStates.NEUTRAL);
			},
			onBlur: async (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
				const value = removeWhiteSpaces(e.target.value);
				if (value === "") {
					fieldStateAtom.set(FieldStates.NEUTRAL);
				} else if (contactFieldData.isValid(value)) fieldStateAtom.set(FieldStates.VALID);
				else fieldStateAtom.set(FieldStates.INVALID);
				if (contactFieldData.type === "email") {
					e.target.value = "";
				}
				valueAtom.set(value);
			},
		};
	}, [contactFieldData, fieldStateAtom, fieldValue, valueAtom]);

	return (
		<>
			<fieldset className={css["field-border"]} data-field-state={fieldState}>
				<legend className={css["field-label"]}>{capitalize(contactFieldData.name)}</legend>
				{contactFieldData.type === "textarea" ? (
					<textarea {...fieldAttributes} rows={10} cols={30} />
				) : (
					<input {...fieldAttributes} type={contactFieldData.type} />
				)}
			</fieldset>
			<div className={css["field-error"]} data-show={fieldState === FieldStates.INVALID}>
				{contactFieldData.error}
			</div>
		</>
	);
}

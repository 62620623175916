import { useCallback, useMemo, useState } from "react";
import Slider from "react-slick";
import ProjectSlide from "@components/projects/projectSlide";
import projects from "@data/projects";
import { isHoverable } from "@utils/mediaQueries";
import css from "@styles/components/projects/projects.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Projects() {
	const [sliderIndex, setSliderIndex] = useState(0);
	const [isSlidable, setIsSlidable] = useState(true);
	const [sliderRef, setSliderRef] = useState<Slider | null>(null);

	const timeoutSlide = () => {
		setIsSlidable(false);
		setTimeout(() => {
			setIsSlidable(true);
		}, 600);
	};

	const sliderSettings = useMemo(() => {
		return {
			arrows: false,
			dots: false,
			infinite: true,
			centerMode: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			speed: 500,
			autoplay: true,
			autoplaySpeed: 2000,
			pauseOnHover: false,
			swipe: !isHoverable && isSlidable,
			beforeChange: (currentSlide: number, nextSlide: number) => setSliderIndex(nextSlide),
			onSwipe: () => {
				timeoutSlide();
			},
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		};
	}, [isSlidable]);

	const sliderPause = useCallback(() => {
		if (sliderRef) {
			sliderRef.slickPause();
		}
	}, [sliderRef]);

	const sliderPlay = useCallback(() => {
		if (sliderRef) {
			sliderRef.slickPlay();
		}
	}, [sliderRef]);

	const setSlide = useCallback(
		(projectIndex: number) => {
			if (sliderRef && isSlidable) {
				if (projectIndex > sliderIndex) {
					if (sliderIndex === 0 && projectIndex === projects.length - 1) sliderRef.slickPrev();
					else sliderRef.slickNext();
				} else {
					if (sliderIndex === projects.length - 1 && projectIndex === 0) sliderRef.slickNext();
					else sliderRef.slickPrev();
				}
				timeoutSlide();
			}
		},
		[sliderIndex, isSlidable, sliderRef],
	);

	return (
		<Slider className={css["projects-container"]} ref={setSliderRef} {...sliderSettings}>
			{projects.map((project, index) => (
				<ProjectSlide
					key={project.title}
					index={index}
					project={project}
					isActive={index === sliderIndex}
					sliderPause={sliderPause}
					sliderPlay={sliderPlay}
					setSlide={setSlide}
				/>
			))}
		</Slider>
	);
}

const removeWhiteSpaces = (string: string): string => {
	return string.replace(/^\s+|\s+$| +(?= )|\n(?=\s{2,})/g, "");
};

const isValidName = (string: string): boolean => {
	return /^[A-Za-z]{2,}(?:(?:[,.] |[ '-])[A-Za-z]+)*(?:\.?)$/.test(removeWhiteSpaces(string));
};

const isValidEmail = (string: string): boolean => {
	return /^[\w-.]+@[\w-.]+(?:\.\w{2,3})+$/.test(removeWhiteSpaces(string));
};

export { removeWhiteSpaces, isValidName, isValidEmail };

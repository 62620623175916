export type Project = {
	title: string;
	description: string;
	img: string;
	link: string;
	skills: string[];
};

const projects: Project[] = [
	{
		title: "WAF WAF",
		description: "A web application firewall capable of scanning local network vulnerabilities and protecting servers from various attacks.",
		img: "/assets/images/projects/waf-waf.png",
		link: "https://github.com/N-R-technologies/WAF-WAF",
		skills: ["Python", "Regex"],
	},
	{
		title: "Portfolio",
		description: "Information about myself and a showcase of my work.",
		img: "/assets/images/projects/portfolio.png",
		link: "https://github.com/RonKonis/Portfolio",
		skills: ["React", "SASS", "TypeScript"],
	},
	{
		title: "Espio",
		description: "Shellcode obfuscation tool to avoid AV/EDR.",
		img: "/assets/images/projects/espio.png",
		link: "https://github.com/Konis-Bros/Espio",
		skills: ["C++", "Python"],
	},
	{
		title: "Undead Zone",
		description: "A universal safety device for pedestrian conveyances alerting users of incoming vehicles.",
		img: "/assets/images/projects/undead-zone.png",
		link: "https://github.com/RonKonis/Undead-Zone",
		skills: ["Python"],
	},
];

export default projects;

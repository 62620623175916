import { Social } from "@data/socials";
import css from "@styles/layout/footer/socialLink.module.scss";

type Props = {
	social: Social;
};

export default function SocialLink({ social }: Props) {
	return (
		<a className={css["social-link"]} href={social.link} target="_blank" rel="noreferrer">
			{social.svg}
		</a>
	);
}

import skills from "@data/skills";
import css from "@styles/components/about/skills.module.scss";

export default function Skills() {
	return (
		<div className={css["skills-container"]}>
			<h4 className={css.title}>My Skills</h4>
			<div className={css["skills-content"]}>
				{skills.map((skill) => (
					<span key={skill} className={css.skill}>
						{skill}
					</span>
				))}
			</div>
		</div>
	);
}

import css from "@styles/layout/header/sectionListItem.module.scss";

type Props = {
	section: string;
};

export default function SectionListItem({ section }: Props) {
	return (
		<li className={css["section-list-item"]}>
			<a className={css["section-link"]} href={`./#${section}`}>
				{section}
			</a>
		</li>
	);
}

import Header from "@layout/header/header";
import Footer from "@layout/footer/footer";
import Section from "@components/misc/section";
import sections from "@data/sections";
import css from "@styles/pages/portfolio.module.scss";

export default function Portfolio() {
	return (
		<div className={css["portfolio-container"]}>
			<Header />
			{sections.map((section) => (
				<Section key={section.id} {...section}>
					<section.element />
				</Section>
			))}
			<Footer />
		</div>
	);
}

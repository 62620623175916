import React from "react";
import { createRoot } from "react-dom/client";
import Portfolio from "@pages/portfolio";
import "@styles/main.module.scss";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<Portfolio />
	</React.StrictMode>,
);

import css from "@styles/components/home/home.module.scss";

export default function Home() {
	return (
		<section id="home" className={css["home-container"]}>
			<div className={css["home-content"]}>
				<h2 className={css.introduction}>Hey! I'm</h2>
				<h1 className={css.name}>Ron Konis</h1>
				<h2 className={css["role-title"]}>Software Developer</h2>
			</div>
		</section>
	);
}

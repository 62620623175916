import Home from "@components/home/home";
import About from "@components/about/about";
import Projects from "@components/projects/projects";
import Contact from "@components/contact/contact";

type Section = {
	id: string;
	title: string;
	element: () => JSX.Element;
};

const sections: Section[] = [
	{
		id: "Home",
		title: "",
		element: Home,
	},
	{
		id: "about",
		title: "About Me",
		element: About,
	},
	{
		id: "projects",
		title: "Projects",
		element: Projects,
	},
	{
		id: "contact",
		title: "Contact",
		element: Contact,
	},
];

export default sections;

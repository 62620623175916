import css from "@styles/components/misc/section.module.scss";

type Props = {
	id: string;
	title: string;
	children: JSX.Element;
};

export default function Section({ id, title, children }: Props) {
	return (
		<section id={id} className={css["section-container"]}>
			<div className={css["section-content"]}>
				{title && (
					<div className={css["section-header"]}>
						<h2 className={css.title}>{title}</h2>
					</div>
				)}
				{children}
			</div>
		</section>
	);
}

import { useMemo } from "react";
import { Project } from "@data/projects";
import css from "@styles/components/projects/projectSlide.module.scss";

type Props = {
	index: number;
	project: Project;
	isActive: boolean;
	sliderPause: () => void;
	sliderPlay: () => void;
	setSlide: (projectIndex: number) => void;
};

export default function ProjectSlide({ index, project, isActive, sliderPause, sliderPlay, setSlide }: Props) {
	const slideAttributes = useMemo(() => {
		if (isActive) {
			return {
				className: `${css["project-slide"]} ${css["active-slide"]}`,
				href: project.link,
				target: "_blank",
				rel: "noreferrer",
				onMouseEnter: sliderPause,
				onMouseLeave: sliderPlay,
			};
		}
		return {
			className: css["project-slide"],
			onClick: () => setSlide(index),
		};
	}, [index, isActive, project.link, setSlide, sliderPause, sliderPlay]);

	return (
		<a {...slideAttributes}>
			<img className={css["project-image"]} src={project.img} alt={project.title} />
			<h4 className={css["project-title"]}>{project.title}</h4>
			<p className={css["project-description"]}>{project.description}</p>
			<div className={css["project-skills"]}>
				{project.skills.map((skill) => (
					<span key={skill} className={css["project-skill"]}>
						{skill}
					</span>
				))}
			</div>
		</a>
	);
}

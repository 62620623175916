import LinkedInSVG from "@svgs/linkedin";
import GitHubSVG from "@svgs/github";

export type Social = {
	link: string;
	svg: JSX.Element;
};

const socials: Social[] = [
	{
		link: "https://www.linkedin.com/in/ronkonis",
		svg: LinkedInSVG,
	},
	{
		link: "https://github.com/RonKonis",
		svg: GitHubSVG,
	},
];

export default socials;

import { useMemo } from "react";
import SocialLink from "@layout/footer/socialLink";
import socials from "@data/socials";
import css from "@styles/layout/footer/footer.module.scss";

const PUBLICATION_YEAR = 2023;

export default function Footer() {
	const currentYear = useMemo(() => new Date().getFullYear(), []);

	return (
		<footer className={css["footer-container"]}>
			<div className={css["socials-container"]}>
				{socials.map((social) => (
					<SocialLink key={social.link} social={social} />
				))}
			</div>
			<p className={css.copyright}>
				&copy; {PUBLICATION_YEAR}
				{currentYear === PUBLICATION_YEAR ? null : ` - ${currentYear}`} Ron Konis. All rights reserved.
			</p>
		</footer>
	);
}

import { atom, map } from "nanostores";

export enum FieldStates {
	INVALID = -1,
	NEUTRAL,
	VALID,
}

type ContactForm = {
	name: string;
	email: string;
	message: string;
};

export type ContactFormKeys = keyof ContactForm;

const contactStore = map({
	name: {
		value: atom(""),
		fieldState: atom(FieldStates.NEUTRAL),
	},
	email: {
		value: atom(""),
		fieldState: atom(FieldStates.NEUTRAL),
	},
	message: {
		value: atom(""),
		fieldState: atom(FieldStates.NEUTRAL),
	},
});

export type ContactStore = typeof contactStore;

export default contactStore;

import { ContactFormKeys } from "@stores/contactStore";
import { isValidName, isValidEmail, removeWhiteSpaces } from "@utils/regex";

type inputTypes = "text" | "email" | "textarea";

export type ContactFieldData = {
	name: ContactFormKeys;
	type: inputTypes;
	isValid: (value: string) => boolean;
	error: string;
};

const contactFields: ContactFieldData[] = [
	{
		name: "name",
		type: "text",
		isValid: (value: string) => isValidName(value),
		error: "Please enter a valid name",
	},
	{
		name: "email",
		type: "email",
		isValid: (value: string) => isValidEmail(value),
		error: "Please enter a valid email address",
	},
	{
		name: "message",
		type: "textarea",
		isValid: (value: string) => removeWhiteSpaces(value).length > 0,
		error: "Please enter a message",
	},
];

export default contactFields;
